/* NAV BAR and SUB NAV BAR STYLE */
.navbarMenu{
    font-size: 24px;
    color: #222;
    font-weight: 600;
    text-decoration: none;
}
.navbarTitle{
    text-decoration: none;
      font-size: 24px;
      color: #222;
      font-weight: 600;
      font-family: Optima,Segoe,Segoe UI,Candara,Calibri,Arial,sans-serif;
  }
  .navbarSubtitle{
    font-size: 12px;
    color: #5a5d5e;
    text-align: center;
    margin-top: -10px;
  }
.navbaritem{
  font-size: 14px;
  font-weight: 500;
  color: #5a5d5e;
  background-color: white;
  padding: 6px 10px;
  margin: 0px 4px;
  transition: all 0.3s ease;
}
/* .navbaritem:hover{
  background-color: #555;
    color: white;
} */
/* .navbaritem:hover .nav-link {
  color: white;
} */
@media (max-width: 1224px) {
  .navbaritem:hover{
    color: #555;
    background-color: white;
  }}
@media (min-width: 1224px) {
  .navbaritem:hover{
    background-color: #555;
    color: white;
  }}
@media (min-width: 1224px) {
  .navbaritem:hover .nav-link {
    color: white;
    background-color: #555;
  }}
@media (max-width: 1224px) {
  .navbaritem:hover .nav-link {
    color: #5a5d5e;
    background-color: white;
  }
}
.dropdownItem{
  font-size: 14px;
  font-weight: 400;
  color: #222;
  transition: all 0.3s ease;
}
.dropdownItem:hover{
  font-weight: 500;
  color:#222;
  background-color: #ddd;
}
.subNavbaritem{
  font-size: 14px;
  font-weight: 500;
  /* color: #5a5d5e; */
  /* color: #555; */
  color: #656565;
  padding: 6px 10px;
  margin: 0px 4px;
  transition: all 0.3s ease;
  display: inline-block;
  float: none;
}
/* /////////////////////////////////////////////////////////////////////////////////// */
.link{
  text-decoration: none;
  color: #333;
}
.link:hover{
  text-decoration: underline;
  color: #333;
}
.homeTitle{
  font-size: 35px;
  font-weight: 700;
  text-align: left;
 }
.portfolioTitle{
 font-size: 46px;
 /* font-size: 54px; */
 font-weight: 700;
 text-align: left;
 margin-bottom: 25px;
 margin-left: 40px;
}
.portfolioSubTitle{
 font-size: 22px;
 font-weight: 600;
 padding-top: 22px;
 margin-left: 40px;
}
.portfolioSmallTitle{
 font-size: 18px;
 font-weight: 500;
 margin-left: 40px;
}
.portfolioTinyTitle{
 font-size: 14px;
 font-weight: 500;
}
.portfolio p,dd,li{
 font-size: 16px;
 color: #656565;
 font-weight: 400;
 max-width: 500px;
 text-align: left;
}
.text p,
.text dd,
.text td,
.text li {
  font-size: 16px;
  color: #656565;
  font-weight: 400;
  text-align: left;
  max-width: 100%;
}
.smallText p,
.smallText li,
.smallText td{
 font-size: 14px;
 color: #656565;
}
.tinyText p{
 font-size: 12px;
 color: #656565;
}
.blogCardText{
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}
/* /////////////////////////////////////////////////////////////////////////////////// */
.placeholderStyle::placeholder{
  color: #999;
}
.placeholderStyle:focus{
  border-color: black;
  box-shadow: 0 0 0 0.25rem rgba(255, 0, 0, 0);
  /* box-shadow: 0 0 0 0.25rem rgba(0, 123, 255, 0.25); */
}
.transparent{
  color: rgba(255, 0, 0, 0) ;
}
.gray{
  color: #555;
}
.mediumBlack{
  color: #333;
}
.black{
  color:black;
}
.blue{
  color:#31a6bf;
}
.orange{
  color:orange;
}
.red{
  color:red;
}
/* /////////////////////////////////////////////////////////////////////////////////// */

.pageContainer{
  padding-top: 80px;
  padding-left: 60px;
  padding-right: 60px;
  /* background-color:chartreuse; */
}
.loginContainer{
  background-color: #323F50;
  height:100%;
 }
 .circleBadge {
  border-radius: 100%;
  width: 35px; 
  height: 35px; 
  display: flex;
  align-items: center;
  justify-content: center;
}
.skyblueRow{
  background-color: skyblue !important;
}












 






