@font-face {
    font-family: telugu-MN;
    src: url('../fonts/telugu-mn.ttf') format('ttf');
    /* src: url('../fonts/telugu-mn.ttf') format('truetype'); */
  }
  @font-face {
    font-family: calibiri-light;
    src: url('../fonts/calibril.ttf') format('ttf');
    /* src: url('../fonts/calibril.ttf') format('truetype'); */
  }
  @font-face {
    font-family: poppins-Black;
    src: url("../fonts/Poppins-Black.ttf");
  }  
  @font-face {
    font-family: poppins-ExtraBold;
    src: url("../fonts/Poppins-ExtraBold.ttf");
  }
  @font-face {
    font-family: poppins-Bold;
    src: url("../fonts/Poppins-Bold.ttf");
  }
  @font-face {
    font-family: poppins-SemiBold;
    src: url("../fonts/Poppins-SemiBold.ttf");
  }
  @font-face {
    font-family: poppins-Medium;
    src: url("../fonts/Poppins-Medium.ttf");
  }
  @font-face {
    font-family: poppins-Regular;
    src: url("../fonts/Poppins-Regular.ttf");
  }
  @font-face {
    font-family: poppins-Thin;
    src: url("../fonts/Poppins-Thin.ttf");
  }
  @font-face {
    font-family: poppins-Light;
    src: url("../fonts/Poppins-Light.ttf");
  }
  @font-face {
    font-family: poppins-ExtraLight;
    src: url("../fonts/Poppins-ExtraLight.ttf");
  }
  .telugu-MN{
    font-family: telugu-MN;
  }
  .calibiri-light{
    font-family: calibiri-light;
  }
  .poppins-Black{
    font-family:  poppins-Black;
  }
  .poppins-ExtraBold{
    font-family:  poppins-ExtraBold;
  }
  .poppins-Bold{
    font-family:  poppins-Bold;
  }
  .poppins-SemiBold{
    font-family:  poppins-SemiBold;
  }
  .poppins-Medium{
    font-family:  poppins-Medium;
  }
  .poppins-Regular{
    font-family:  poppins-Regular;
  }
  .poppins-Thin{
    font-family:  poppins-Thin;
  }
  .poppins-Light{
    font-family:  poppins-Light;
  }
  .poppins-ExtraLight{
    font-family:  poppins-ExtraLight;
  }